import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface AuthCredentials {
  auth: boolean;
  role: string;
  data: {
    token: string;
    admin_id: string;
    user_id: string;
    name: string;
    image: string;
  };
}

const AuthCredential = createSlice({
  name: "AuthCredential",
  initialState: {
    auth: {
      auth: false,
      role: "",
      data: {
        token: "",
        admin_id: "",
        user_id: "",
        name: "",
        image: "",
      },
    },
  },
  reducers: {
    setAuth: (state, action: PayloadAction<AuthCredentials>) => {
      state.auth = action.payload;
    },
  },
});

export const AuthActions = AuthCredential.actions;
export default AuthCredential;
