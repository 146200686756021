import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../store/stateHooks";
import Header from "../components/users/dashboard/header";
import Footer from "../components/Admin/Layout/Footer";
import Sidebar from "../components/users/dashboard/Sidebar";
import Layout from "../components/users/dashboard/layout";
function Protected({ children }) {
  const { auth } = useAppSelector((state) => state.authCredential.auth);
  const checkAuth = auth;
  return checkAuth === true ? children : <Navigate to="/admin-login" replace />;
}

export const PrivateRoute = ({ isAuthenticated }) => {
  return isAuthenticated ? (
    <>
      <Layout />
      <div style={{ backgroundColor: '#ebfdfd', }} className={`vh-auto `}>
        <Outlet />
      </div>
      <Footer />
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default Protected;
