import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import showToast from "../../../../utils/toast";
import { CustomModal } from "../../../modals";
import { useAppSelector } from "../../../../store/stateHooks";
import lang from "../../../../utils/language/french";
import { AddContactForm } from "../../../UI/forms";
import { deleteAPI, getAPI } from "../../../../apis/Services";
import { api } from "../../../../utils/storage";
import ContactTable from "./contactTable";

export default function ContactScreen() {
  const { auth } = useAppSelector((state) => state.authCredential);
  const [showModal, setShowModal] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [statusFilter, setStatusFilter] = useState("Tous");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAPI(`${api.contact}/${auth.data.admin_id}`);
        if (res.status === 200) {
          setContacts(res.data);
        } else {
          showToast(res.message, "error");
        }
      } catch (err) {
        showToast("Une erreur s'est produite lors de la récupération des contacts", "error");
      }
    };

    fetchData();
  }, [auth.data.admin_id]);

  const handleCancel = () => {
    setShowModal(false);
  };

  const filteredContacts = contacts.filter((contact) => {
    if (statusFilter === "Tous") return true;
    return contact.status === statusFilter;
  });

  const handleDelete = async (contactId) => {
    const confirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer ce contact ?"
    );
    if (confirmed) {
      try {
        const res = await deleteAPI(`${api.contact}/${auth.data.admin_id}`, {
          id: contactId,
        });
        setContacts((prevContacts) =>
          prevContacts.filter((contact) => contact._id !== contactId)
        );
        showToast(res.message, "success");
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleAddContact = (newContact) => {
    setContacts((prevContacts) => [newContact, ...prevContacts]);
    setShowModal(false);
  };
  return (
    <>
      <main id="main" className="main">
        <CustomModal
          show={showModal}
          onCancel={handleCancel}
          title="Ajouter un contact"
        >
          <AddContactForm
            handleCancel={handleCancel}
            handleAddContact={handleAddContact}
          />
        </CustomModal>
        <div className="pagetitle">
          <h1>{lang.offer.Offres}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">{lang.common.home}</Link>
              </li>
              <li className="breadcrumb-item active">{"Contact"}</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="container mt-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="fw-bold">Contacts</h1>
              <div className="d-flex">
                <div className="d-flex mt-3">
                  <button
                    className={`btn btn-light me-2 ${
                      statusFilter === "Tous" ? "active" : ""
                    }`}
                    onClick={() => setStatusFilter("Tous")}
                  >
                    Tous
                  </button>
                  <button
                    className={`btn btn-light me-2 ${
                      statusFilter === "Clients" ? "active" : ""
                    }`}
                    onClick={() => setStatusFilter("Clients")}
                  >
                    Clients
                  </button>
                  <button
                    className={`btn btn-light ${
                      statusFilter === "Prospects" ? "active" : ""
                    }`}
                    onClick={() => setStatusFilter("Prospects")}
                  >
                    Prospects
                  </button>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => setShowModal(true)}
                >
                  <i className="bi bi-plus"></i> Nouveau contact
                </button>
              </div>
            </div>
            <ContactTable
              tbody={filteredContacts}
              handleDelete={handleDelete}
            />
          </div>
        </section>
      </main>
    </>
  );
}
