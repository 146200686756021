import { useForm } from "react-hook-form";
import InputField from "../../../users/components/inputField";
import lang from "../../../../utils/language/french";
import { postAPI, putAPI } from "../../../../apis/Services"; // Assume updateAPI exists
import { api } from "../../../../utils/storage";
import { useAppSelector } from "../../../../store/stateHooks";
import showToast from "../../../../utils/toast";

const AddContactForm = ({ handleCancel, handleAddContact, formData }) => {
  const {
    firstName = "",
    lastName = "",
    phone = "",
    status = "",
    entreprise = "",
    email = "",
    civility = "M",
    adminId = "",
    createdAt = "",
  } = formData || {};

  const { auth } = useAppSelector((state) => state.authCredential);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      firstName,
      lastName,
      phone,
      status,
      entreprise,
      email,
      civility,
    },
  });

  const onSubmit = async (data) => {
    try {
      let res;
      if (formData) {
        // Update contact if formData exists
        res = await putAPI(`${api.contact}/${auth.data.admin_id}`, {
          ...data,
          id: formData._id,
        });
        showToast("Contact mis à jour avec succès", "success");
      } else {
        // Create new contact if formData doesn't exist
        res = await postAPI(`${api.contact}/${auth.data.admin_id}`, data);
        showToast("Contact créé avec succès", "success");
        handleAddContact(res.data);
      }
      reset();
      handleCancel();
    } catch (err) {
      console.log(err);
      showToast(lang.common.error, "error");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="m-4">
      <strong>
        {formData
          ? "Modifier les informations du contact."
          : "Renseignez les différents champs obligatoire afin de créer votre contact."}
      </strong>

      <div className="mt-3 d-flex">
        <label className="form-label fw-normal" style={{ color: "#000" }}>
          Civilité :
        </label>
        <div className="ms-2">
          <input
            type="radio"
            id="male"
            value="M"
            {...register("civility", { required: true })}
            className="form-check-input"
          />
          <label
            className="form-check-label ms-2"
            htmlFor="male"
            style={{ color: "#5a606f" }}
          >
            M
          </label>

          <input
            type="radio"
            id="female"
            value="Mme"
            {...register("civility", { required: true })}
            className="form-check-input ms-5"
          />
          <label
            className="form-check-label ms-2"
            htmlFor="female"
            style={{ color: "#5a606f" }}
          >
            Mme
          </label>
        </div>
        {errors.civility && (
          <p className="text-danger">Civilité est obligatoire</p>
        )}
      </div>

      <div className="row">
        <div className="col">
          <InputField
            key="firstName"
            label="* Nom"
            name="firstName"
            type="text"
            placeholder="Nom"
            register={register}
            errors={errors}
            validation={{ required: "Nom est obligatoire" }}
          />
        </div>
        <div className="col">
          <InputField
            key="lastName"
            label="* Prénom"
            name="lastName"
            type="text"
            placeholder="Prénom"
            register={register}
            errors={errors}
            validation={{ required: "Prénom est obligatoire" }}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col">
          <InputField
            key="email"
            label="* Adresse mail"
            name="email"
            type="email"
            placeholder="Adresse mail"
            register={register}
            errors={errors}
            validation={{
              required: "Adresse mail est obligatoire",
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: "Format d'e-mail invalide",
              },
            }}
          />
        </div>

        <div className="col">
          <InputField
            key="phone"
            label="* Téléphone"
            name="phone"
            type="tel"
            placeholder="Téléphone"
            register={register}
            errors={errors}
            validation={{
              required: "Téléphone est obligatoire",
              pattern: {
                value: /^[0-9]*$/,
                message:
                  "Le numéro de téléphone doit contenir uniquement des chiffres",
              },
            }}
          />
        </div>
      </div>

      <div className="mt-3">
        <InputField
          key="entreprise"
          label="Entreprise"
          name="entreprise"
          type="text"
          placeholder="Entreprise"
          register={register}
          errors={errors}
        />
      </div>

      <div className="d-flex justify-content-between mt-4 gap-3 ">
        <button
          type="button"
          className="btn btn-outline-primary w-50"
          onClick={() => handleCancel()}
        >
          Annuler
        </button>
        <button type="submit" className="btn btn-primary w-50">
          {formData ? "Mettre à jour le contact" : "Ajouter un contact"}
        </button>
      </div>
    </form>
  );
};

export default AddContactForm;
