import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store/stateHooks";
import lang from "../../../utils/language/french"
function AddNewAdmin() {
  const SERVER_URL = process.env.REACT_APP_API_SERVER;
  const { auth } = useAppSelector((state) => state.authCredential);

  // On submit function
  const handelsubmit = async (event) => {
    event.preventDefault();
    document.getElementById(
      "addNewBtn"
    ).innerHTML = `<div style="height:22px;width:22px" class="spinner-border text-light"></div>`;
    const name = event.target.name.value;
    const email = event.target.email.value;
    const password = event.target.password.value;
    const phone = event.target.phone.value;
    const role = event.target.role.value;

    const messages = document.getElementById("messages");
    messages.innerHTML = "";
    //======================|LOGIN API|==========================
    var BASE_URL = SERVER_URL + "/api/v1/admin/register";

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth.data.token}`);

    var raw = JSON.stringify({
      name: name,
      email: email,
      password: password,
      phone: phone,
      role: role,
      admin_id: auth.data.admin_id,
    });

    let resData = await fetch(BASE_URL, {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    }).then((res) => {
      return res.json();
    });

    document.getElementById("addNewBtn").innerHTML = lang.admin.addNew;
    if (resData.status === 200) {
      messages.innerHTML = `
        <div class="alert alert-success alert-dismissible fade show">
        <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
        <strong>${lang.common.success}!</strong> ${resData.message}
      </div>`;

      event.target.reset();
      setTimeout(() => {
        messages.innerHTML = "";
      }, 10000);
    } else {
      messages.innerHTML = `
        <div class="alert alert-danger alert-dismissible fade show">
        <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
        <strong>${lang.common.error}!</strong> ${resData.message}
      </div>`;
    }
  };
  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{lang.manageAdmin.addNewAdmin}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">{lang.common.home}</Link>
              </li>
              <li className="breadcrumb-item active">{lang.manageAdmin.addNewAdmin}</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="card-title">{lang.manageAdmin.addAdmin}</h5>
                  <Link to="/admin/manage-admins">
                    <button className="btn btn-primary">{lang.manageAdmin.manage}</button>
                  </Link>
                </div>
                <div id="messages"></div>
                <form
                  className="row g-3"
                  autoComplete="off"
                  onSubmit={handelsubmit}
                >
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Nom de l'administrateur"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder={lang.common.enterEmail}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder={lang.admin.password}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      placeholder={lang.admin.offerHeader.phoneNumber}
                    />
                  </div>
                  <div className="col-md-6">
                    <select
                      id="inputState"
                      name="role"
                      className="form-control"
                      defaultValue=""
                    >
                      <option value="" selected disabled>
                        {lang.admin.selectRole}
                      </option>
                      <option value="super-admin">{`Super ${lang.admin.admin}`}</option>
                      <option value="admin">{lang.admin.admin}</option>
                      <option value="sub-admin">{lang.admin.subAdmin}</option>
                    </select>
                  </div>
                  <div className="text-end">
                    <button
                      type="submit"
                      id="addNewBtn"
                      className="btn btn-primary"
                    >
                      {lang.admin.addNew}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default AddNewAdmin;
