// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { useMemo } from "react";
// import lang from "../../../utils/language/french";
// function SideBar() {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const sidebarData = useMemo(
//     () => [
//       {
//         name: lang.common.dashboard, // Dashboard
//         icon: "bi bi-grid",
//         path: "/admin/dashboard",
//       },
//       {
//         name: "Mes offres",
//         icon: "bi bi-database",
//         path: "/admin/mes-offres",
//       },
//       {
//         name: lang.common.manageOffers, // Manage offers
//         icon: "bi bi-columns",
//         path: "/admin/submitted-offers",
//       },
//       {
//         name: lang.common.manageUsersQueries, // Manage Users Queries
//         icon: "bi bi-layout-text-window-reverse",
//         path: "/admin/users/queries",
//       },
//       {
//         name: "CRM", // Manage Users Queries
//         icon: "bi bi-journal-medical",
//         path: [
//           {
//             name: "Mes offres",
//             icon: "bi bi-database",
//             path: "/admin/mes-offres",
//           },
//           {
//             name: lang.common.manageOffers, // Manage offers
//             icon: "bi bi-columns",
//             path: "/admin/submitted-offers",
//           },
//         ],
//       },
//     ],
//     []
//   );

//   return (
//     <aside id="sidebar" className="sidebar">
//       <ul className="sidebar-nav" id="sidebar-nav">
//         <div className="mt-2">
//           <button
//             type="button"
//             className="btn btn-primary mx-1 fw-bold"
//             onClick={() => navigate("/")}
//           >
//             + Créer une offre
//           </button>
//         </div>
//         {sidebarData.map((menu) => (
//           <li className="nav-item" key={menu.path}>
//             <Link
//               to={menu.path}
//               className={`nav-link ${
//                 location.pathname === menu.path ? "" : "collapsed"
//               }`}
//             >
//               <i className={menu.icon} />
//               <span>{menu.name}</span>
//             </Link>
//           </li>
//         ))}
//       </ul>
//     </aside>
//   );
// }

// export default SideBar;

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import lang from "../../../utils/language/french";

function SideBar() {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [activeMenu, setActiveMenu] = useState(null);

  const sidebarData = useMemo(
    () => [
      {
        name: lang.common.dashboard, // Dashboard
        icon: "bi bi-grid",
        path: "/admin/dashboard",
      },
      {
        name: "Mes offres",
        icon: "bi bi-database",
        path: "/admin/mes-offres",
      },
      {
        name: lang.common.manageOffers, // Manage offers
        icon: "bi bi-columns",
        path: "/admin/submitted-offers",
      },
      {
        name: lang.common.manageUsersQueries, // Manage Users Queries
        icon: "bi bi-layout-text-window-reverse",
        path: "/admin/users/queries",
      },
      {
        name: "CRM", // CRM with submenu
        icon: "bi bi-journal-medical",
        submenu: [
          {
            name: "Entreprises",
            icon: "bi bi-person",
            path: "/admin/entreprise",
          },
          {
            name: "Contacts", // Manage offers
            icon: "bi bi-people",
            path: "/admin/contact",
          },
        ],
      },
    ],
    []
  );

  const toggleSubMenu = (menuName) => {
    setActiveMenu(activeMenu === menuName ? null : menuName);
  };

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <div className="mt-2">
          <button
            type="button"
            className="btn btn-primary mx-1 fw-bold"
            onClick={() => navigate("/")}
          >
            + Créer une offre
          </button>
        </div>
        {sidebarData.map((menu) => (
          <li className="nav-item" key={menu.name}>
            {menu.submenu ? (
              <>
                <a
                  href="#!"
                  className={`nav-link ${activeMenu === menu.name ? "" : "collapsed"}`}
                  onClick={() => toggleSubMenu(menu.name)}
                >
                  <i className={menu.icon} />
                  <span>{menu.name}</span>
                  <i className={`bi ${activeMenu === menu.name ? "bi-chevron-up" : "bi-chevron-down"} ms-auto`}></i>
                </a>
                <ul className={`submenu ${activeMenu === menu.name ? "active" : "collapse"}`}>
                  {menu.submenu.map((submenuItem) => (
                    <li key={submenuItem.path}>
                      <Link
                        to={submenuItem.path}
                        className={`nav-link ${location.pathname === submenuItem.path ? "" : "collapsed"}`}
                      >
                        <i className={submenuItem.icon} />
                        <span>{submenuItem.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <Link
                to={menu.path}
                className={`nav-link ${location.pathname === menu.path ? "" : "collapsed"}`}
              >
                <i className={menu.icon} />
                <span>{menu.name}</span>
              </Link>
            )}
          </li>
        ))}
      </ul>
    </aside>
  );
}

export default SideBar;
