import React, { useState } from "react";
import { Link } from "react-router-dom";

const DevisContrats = () => {
  const [statusFilter, setStatusFilter] = useState("devis");
  return (
    <div
      className="p-4 bg-light"
      style={{
        border: "1px solid black",
        borderRadius: "5px",
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="fw-bold">Devis et contrats</h3>
        <div
          className="d-flex"
          style={{ backgroundColor: "#eaeaee", borderRadius: 10 }}
        >
          <div className="d-flex p-1 gap-1">
            <button
              className={`btn btn-light p-1 ${
                statusFilter === "devis" ? "active" : ""
              }`}
              onClick={() => setStatusFilter("devis")}
            >
              Devis
            </button>
            <button
              className={`btn btn-light p-1 ${
                statusFilter === "contrats" ? "active" : ""
              }`}
              onClick={() => setStatusFilter("contrats")}
            >
              Contrats
            </button>
          </div>
        </div>
      </div>
      <div className="text-center mt-3 py-3" style={{backgroundColor: "#eaeaee", borderRadius: 10 }}>
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <div className="d-flex align-items-center">
            <i className="bi bi-info-circle fs-4"></i>
            <p className="ms-2 mb-0 fs-5">
              Vous n'avez aucun contrats enregistré
            </p>
          </div>

          <Link
            className="btn btn-outline-primary mt-4"
            // onClick={() => setShowModal(true)}
            to={"/admin/dashboard"}
          >
            <i className="bi bi-plus"></i> Nouveau devis
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DevisContrats;
